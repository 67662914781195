/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Relation {
  CONTRADICTION = "CONTRADICTION",
  ENTAILMENT = "ENTAILMENT",
  NOT_ENTAILMENT = "NOT_ENTAILMENT",
  PARAPHRASE = "PARAPHRASE",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
