import { combineReducers } from "redux";
import { configureStore, createReducer, createAction } from "@reduxjs/toolkit";

export const setTextA = createAction<string>("setTextA");
export const setTextB = createAction<string>("setTextB");
export const setNumLabels = createAction<number>("setNumLabels");
export const querySlowly = createAction<number>("querySlowly");

const textBReducer = createReducer("", (builder) => {
  builder.addCase(setTextB, (_, action) => action.payload);
});

const rootReducer = combineReducers({
  texta: createReducer("", (builder) => {
    builder.addCase(setTextA, (_, action) => action.payload);
  }),
  textb: textBReducer,
  numLabels: createReducer(3, (builder) => {
    builder.addCase(setNumLabels, (_, action) => action.payload);
  }),
  querySlowly: createReducer(0, (builder) => {
    builder.addCase(querySlowly, (state, action) => {
      window.clearTimeout(state);
      return action.payload;
    });
  }),
});

export const store = configureStore({ reducer: rootReducer, devTools: true });
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
