import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  TextField,
  Container,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from "@material-ui/core";
import { useLazyQuery } from "@apollo/react-hooks";
import "./App.css";
import {
  RootState,
  setTextA,
  setTextB,
  setNumLabels,
  querySlowly,
} from "./store";
import { INFER_RELATION } from "./queries";
import { Query, QueryVariables } from "./types/Query";
import { Relation } from "./types/graphql-global-types";
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector as useUntypedSelector,
} from "react-redux";
const useSelector: TypedUseSelectorHook<RootState> = useUntypedSelector;

function App() {
  return (
    <Container maxWidth="md">
      <Grid
        container
        spacing={3}
        direction="column"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Home />
      </Grid>
    </Container>
  );
}

const Home = () => {
  const dispatch = useDispatch();
  const texta = useSelector((state) => state.texta);
  const textb = useSelector((state) => state.textb);
  return (
    <>
      <Grid item xs={12}>
        <TextField
          placeholder="Input first text here"
          fullWidth
          variant="outlined"
          multiline
          rows={2}
          rowsMax={20}
          value={texta}
          onChange={(e) => dispatch(setTextA(e.target.value))}
        />
      </Grid>
      <Grid item xs={12}>
        <RelationResult />
      </Grid>
      <Grid item xs={12}>
        <TextField
          placeholder="Input second text here"
          fullWidth
          variant="outlined"
          multiline
          rows={2}
          rowsMax={20}
          value={textb}
          onChange={(e) => dispatch(setTextB(e.target.value))}
        />
      </Grid>
      <Grid item xs={12}>
        <NumLabelSelector />
      </Grid>
    </>
  );
};

const NumLabelSelector = () => {
  const numLabels = useSelector((state) => state.numLabels);
  const dispatch = useDispatch();
  const labels = [3, 4];
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">number of labels</FormLabel>
      <RadioGroup row defaultValue={3}>
        {labels.map((i) => (
          <FormControlLabel
            value={i}
            control={<Radio color="primary" />}
            label={i}
            labelPlacement="end"
            checked={numLabels == i}
            key={i}
            onChange={(e) =>
              dispatch(
                setNumLabels(parseInt((e.target as HTMLInputElement).value))
              )
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const RelationResult = () => {
  const numLabels = useSelector((state) => state.numLabels);
  const texta = useSelector((state) => state.texta);
  const textb = useSelector((state) => state.textb);
  const dispatch = useDispatch();
  const showresult = !!texta && !!textb;
  const [getResult, { error, loading, data }] = useLazyQuery<
    Query,
    QueryVariables
  >(INFER_RELATION);

  // Delay to fetch to reduce the number of the queries.
  if (texta && textb) {
    dispatch(
      querySlowly(
        window.setTimeout(() => {
          getResult({
            variables: { texta: texta, textb: textb, numLabels: numLabels },
          });
        }, 500)
      )
    );
  }

  let svg = "";
  let text = "";
  switch (data?.relation) {
    case Relation.ENTAILMENT:
      svg = "./entailment-icon.svg";
      text = "Entailment";
      break;
    case Relation.NOT_ENTAILMENT:
      svg = "./neutral-icon.svg";
      text = "Neutral";
      break;
    case Relation.PARAPHRASE:
      svg = "./paraphrase-icon.svg";
      text = "Paraphrase";
      break;
    case Relation.CONTRADICTION:
      svg = "./contradiction-icon.svg";
      text = "Contradiction";
    default:
      break;
  }

  return (
    <Grid container style={{ minWidth: "100%" }}>
      <Grid
        item
        xs={4}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {showresult && !loading && !error ? (
          <Typography variant="h4">{text}</Typography>
        ) : (
            <></>
          )}
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "25vh",
        }}
      >
        {!showresult ? "" : loading ? <CircularProgress /> : <img src={svg} />}
      </Grid>
      <Grid item xs={4}>
        {showresult && !loading && !error ? (
          <Similarity p={data?.similarity} />
        ) : (
            <></>
          )}
      </Grid>
    </Grid >
  );
};

const Similarity = ({ p }: { p: number | null | undefined }) => (
  <Grid container>
    <Grid item xs={12}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "25vh",
        flexDirection: "column"
      }}
    >
      Similarity<br />
      < Typography variant="h4">
        {p?.toFixed(3)}
      </Typography>
    </Grid>
  </Grid>
)

export default App;
